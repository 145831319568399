<template>
  <b-card>
    <div id="placeholder" style="height: 58vh" />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'MediaCollaborativeDocuments',
  components: { BCard },
  data() {
    return {
      isLoading: true,
      isLoadingPage: false,
      numPages: null,
      currentPage: 1,
      isFullscreen: false,
    };
  },
  computed: {
    config() {
      document.addEventListener('placeholder', () => {}, { passive: false });
      return {
        document: {
          fileType: this.typeFile,
          key: this.$route.params.itemKey,
          title: this.translate(this.collaborativeFile?.name),
          url: this.collaborativeFile?.url,
          permissions: {
            edit: !!this.collaborativeFile?.isCollaborative,
            rename: !!this.collaborativeFile?.isCollaborative,
            review: !!this.collaborativeFile?.isCollaborative,
            comment: !!this.collaborativeFile?.isCollaborative,
            chat: !!this.collaborativeFile?.isCollaborative,
            download: this.collaborativeFile?.canDownload,
          },
        },
        documentType: this.documentType,
        editorConfig: {
          callbackUrl: `${process.env.VUE_APP_API}/api/v1/collaborativeDocuments`,
          user: {
            group: '',
            id: this.currentUser.id,
            name: this.currentUser.name,
          },
        },
        height: '',
      };
    },
    collaborativeFile() {
      return this.$store.getters.fragmentedItem('media', this.$route.params.itemKey, 'col') || this.$store.getters.item('storage', this.$route.params.itemKey);
    },

    typeFile() {
      const collaborativeFileType = this.collaborativeFile?.url.substring(this.collaborativeFile?.url.lastIndexOf('.') + 1);
      return collaborativeFileType.substring(0, collaborativeFileType.indexOf('?X-Amz'));
    },
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    currentMember() {
      return this.$store.getters.loggedMember;
    },
    documentType() {
      if (this.$store.getters.fragmentedItem('media', this.$route.params.itemKey, 'col')?.url.includes('pptx') || this.$store.getters.item('storage', this.$route.params.itemKey)?.url.includes('pptx')) {
        return 'slide';
      }
      if (this.$store.getters.fragmentedItem('media', this.$route.params.itemKey, 'col')?.url.includes('xlsx') || this.$store.getters.item('storage', this.$route.params.itemKey)?.url.includes('xlsx')) {
        return 'cell';
      }
      return 'word';
    },
    typeName() {
      if (this.$store.getters.item('storage', this.$route.params.itemKey)?.url) {
        return 'file';
      }
      if (this.$store.getters.fragmentedItem('media', this.$route.params.itemKey, 'col')?.url.includes('pptx')) {
        return 'presentation';
      }
      if (this.$store.getters.fragmentedItem('media', this.$route.params.itemKey, 'col')?.url.includes('xlsx')) {
        return 'spreadsheet';
      }
      return 'document';
    },
  },
  async created() {
    await this.fetchData();
    setTimeout(() => {
      this.openDocument();
    }, 500);
  },
  methods: {
    translate(data) {
      if (data != null) {
        return translateTranslationTable(this.$store.state.locale.currentLocale, data) || Object.values(data)[0];
      }
      return 'title';
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'media',
        storedKey: 'col',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          mediaKey: this.$route.params.itemKey,
        },
      });

      await this.$store.dispatch('getItems', {
        itemType: 'storage',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          literal: true,
          morphType: 'file',
          mediaKey: this.$route.params.itemKey,
        },
      });
    },
    openDocument() {
      DocsAPI.DocEditor('placeholder', this.config);
      this.updateBreadcrumbs();
    },
    updateBreadcrumbs() {
      const mediaTypeNamePlural = this.typeName === 'file' ? 'storage' : `${this.typeName}s`;
      const params = {
        communityId: this.$route.params.communityId,
      };
      if (this.typeName === 'file') {
        params.itemKey = this.collaborativeFile?.key;
      } else {
        params.id = this.collaborativeFile?.key,
        params.media = this.$route.params.media;
      }
      const breadcrumbs = [
        {
          text: `${mediaTypeNamePlural}.breadcrumb-text.${mediaTypeNamePlural}-list`,
          to: { name: `${mediaTypeNamePlural}` },
        },
        {
          text: this.translate(this.collaborativeFile?.name),
          to: { name: this.typeName === 'file' ? 'storage-details' : `${this.typeName}-details`, params },
        },
        {
          text: 'media.collaborative-breadcrumb',
          active: true,
        },
      ];

      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
  },
};
</script>
